import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

export function Rooms({
  availableRooms,
  selectedRoomsData,
  isRoomFetchingLoading,
  addRoomOnSelected,
  removeRoomOnSelected,
}) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {isRoomFetchingLoading ? <CircularProgress /> : null}
      <Typography>Salas selecionadas</Typography>
      {selectedRoomsData.length > 0 ? (
        <Grid container spacing={1}>
          {selectedRoomsData.map((room) => (
            <Grid item spacing={8} key={room.id.toString()}>
              <Chip
                label={room.name}
                avatar={<Avatar alt={room.name} src={room.thumbnail_url} />}
                onDelete={() => removeRoomOnSelected(room.id)}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="body2" style={{ marginLeft: 5 }}>
          Nenhuma sala encontrada
        </Typography>
      )}
      <Typography style={{ marginTop: 10 }}>Salas</Typography>
      {availableRooms.length > 0 ? (
        <Grid container spacing={10}>
          {availableRooms.map((room) => (
            <Grid item spacing={8} key={room.id.toString()}>
              <Chip
                label={room.name}
                onClick={() => addRoomOnSelected(room.id)}
                avatar={<Avatar alt={room.name} src={room.thumbnail_url} />}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="body2" style={{ marginLeft: 5 }}>
          Todas as salas foram selecionadas
        </Typography>
      )}
    </div>
  );
}
