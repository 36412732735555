import React from "react";
import Typography from "@material-ui/core/Typography";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  FunctionField
} from "react-admin";
import { format } from 'date-fns'

const PageList = (props) => {
  
  return (
    <List title="Páginas" {...props}>
      <Datagrid rowClick={'show'}>
        <TextField source="id" label="ID" />
        <TextField source="name" label="Nome" />
        <FunctionField label="Data de criação" render={record => <Typography>{format(new Date(record.createdAt), 'dd/MM/yyyy')}</Typography>} />
        <EditButton basePath="/pages-content" />
        <DeleteButton basePath="/pages-content" />
      </Datagrid>
    </List>
  );
};

export default PageList;
