import React, { useCallback, useEffect, useState } from "react";
import CardActions from "@material-ui/core/CardActions";
import axios from "axios";
import {
  Show,
  SimpleShowLayout,
  TextField,
  ImageField,
  EditButton,
  RefreshButton,
} from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import { Constants } from "../../constants";

const PageShow = (props) => {
  const [rooms, setRooms] = useState([]);
  const [isRoomFetchingLoading, setRoomFetchingLoading] = useState(true);

  const getRooms = useCallback(async () => {
    setRoomFetchingLoading(true);
    try {
      const { data } = await axios.get(`${Constants.ApiUrl}/rooms`, {
        params: { range: "[0,1000]" },
      });
      setRooms(data);
    } catch (error) {
      console.error(error);
    } finally {
      setRoomFetchingLoading(false);
    }
  }, []);

  useEffect(() => {
    getRooms();
  }, [getRooms]);

  const Title = ({ record }) => {
    return <span>Página {record ? `"${record.name}"` : ""}</span>;
  };

  const ShowActions = ({ basePath, data, resource }) => (
    <CardActions>
      <EditButton basePath={basePath} record={data} />
      <RefreshButton basePath={basePath} record={data} />
    </CardActions>
  );

  return (
    <Show title={<Title />} actions={<ShowActions />} {...props}>
      <SimpleShowLayout rowClick={"edit"}>
        <TextField source="id" label="ID" />
        <TextField source="name" label="Nome" />,
        <TextField source="description" label="Descrição" />
        <ImageField source="main_image_url" label="Imagem 1" />
        <Typography>Salas</Typography>
        {isRoomFetchingLoading ? <CircularProgress /> : null}
        <Grid container spacing={12}>
          {rooms.map((room) => (
            <Grid item spacing={8}>
              <Chip
                key={room.id.toString()}
                label={room.name}
                avatar={<Avatar alt={room.name} src={room.thumbnail_url} />}
              />
            </Grid>
          ))}
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};

export default PageShow;
