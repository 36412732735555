import dataProvider from './dataProvider';

const myDataProvider = {
    ...dataProvider,
    create: async (resource, params) => {

        if(resource === 'galery'){
            const { image1, image2, image3, image4, image5, image6 } = params.data;

            const imagesArray = [image1, image2, image3, image4, image5, image6];

            let newImages = []

            for await (const image of imagesArray){
                if(image){
                    const base64 = await convertFileToBase64(image).then(base64image =>{
                        return {
                            src: base64image,
                            title: `${params.data.title}`
                        }
                    })
                    newImages = [...newImages, base64];
                }
            }

            return dataProvider.create(resource, {
                ...params,
                data: {
                    ...params.data,
                    newImages
                }
            })
        }

        if(resource === 'pages'){
            const { image1, image2, image3, image4, image5, image6 } = params.data;

            const imagesArray = [image1, image2, image3, image4, image5, image6];

            let newImages = []

            for await (const image of imagesArray){
                if(image){
                    const base64 = await convertFileToBase64(image).then(base64image =>{
                        return {
                            src: base64image,
                            title: `${params.data.title}`
                        }
                    })
                    newImages = [...newImages, base64];
                }
            }

            return Promise.resolve(convertFileToBase64(params.data.main_image))
                .then((base64) => ({
                    src: base64,
                    title: params.data.main_image?.title || 'main_image'
                }))
                .then(file => dataProvider.create(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        main_image: file,
                        newImages
                    }
                }))
        }

        if (!params.data.image && !params.data.main_image && !params.data.thumbnail && !params.data.desktopImage && !params.data.mobileImage) {
            // fallback to the default implementation
            return dataProvider.create(resource, params);
        }
        
        // Freshly dropped pictures are File objects and must be converted to base64 strings
        if(resource === 'services'){
            const newImage = params.data.image;

            return Promise.resolve( convertFileToBase64(newImage) )
                .then( (picture64) => ({
                    src: picture64,
                    title: `${newImage.title}`
                }))
                .then( transformedMyFile => dataProvider.create(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        image: transformedMyFile
                    }
                }));
        }

        if(resource === 'posts'){

            const newImage = params.data.thumbnail;

            return Promise.resolve( convertFileToBase64(newImage) )
                .then( (picture64) => ({
                    src: picture64,
                    title: `${newImage.title}`
                }))
                .then( transformedMyFile => dataProvider.create(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        thumbnail: transformedMyFile
                    }
                }));
        }

        if(resource === 'events'){

            const newImage = params.data.thumbnail;

            return Promise.resolve( convertFileToBase64(newImage) )
                .then( (picture64) => ({
                    src: picture64,
                    title: `${newImage.title}`
                }))
                .then( transformedMyFile => dataProvider.create(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        thumbnail: transformedMyFile
                    }
                }));
        }

        if(resource === 'plans'){

            const newImage = params.data.image;

            return Promise.resolve( convertFileToBase64(newImage) )
                .then( (picture64) => ({
                    src: picture64,
                    title: `${newImage.title}`
                }))
                .then( transformedMyFile => dataProvider.create(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        image: transformedMyFile
                    }
                }));
        }
        
        if(resource === 'rooms'){

            const { image1, image2, image3, image4, image5, image6 } = params.data;

            const imagesArray = [image1, image2, image3, image4, image5, image6];

            let newImages = [];

            for await (const image of imagesArray){
                if(image){
                    const base64 = await convertFileToBase64(image).then(base64image =>{
                        return {
                            src: base64image,
                            title: `${params.data.title}`
                        }
                    })
                    newImages = [...newImages, base64];
                }
            }

            const newImage = params.data.thumbnail;

            return Promise.resolve( convertFileToBase64(newImage) )
                .then( (picture64) => ({
                    src: picture64,
                    title: `${newImage.title}`
                }))
                .then( transformedMyFile => dataProvider.create(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        thumbnail: transformedMyFile,
                        newImages
                    }
                }));
        }

        if(resource === 'spaces'){

            const { image1, image2, image3, image4, image5, image6 } = params.data;

            const imagesArray = [image1, image2, image3, image4, image5, image6];

            let newImages = [];

            for await (const image of imagesArray){
                if(image){
                    const base64 = await convertFileToBase64(image).then(base64image =>{
                        return {
                            src: base64image,
                            title: `${params.data.title}`
                        }
                    })
                    newImages = [...newImages, base64];
                }
            }

            const newImage = params.data.thumbnail;

            return Promise.resolve( convertFileToBase64(newImage) )
                .then( (picture64) => ({
                    src: picture64,
                    title: `${newImage.title}`
                }))
                .then( transformedMyFile => dataProvider.create(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        thumbnail: transformedMyFile,
                        newImages
                    }
                }));
        }

        if(resource === 'advertisements'){
            const desktopImage = params.data.desktopImage;
            const mobileImage = params.data.mobileImage

            const newMobileImage = await convertFileToBase64(mobileImage).then((picture64) => ({
                src: picture64,
                title: `${mobileImage.title}`
            }))

            return Promise.resolve( convertFileToBase64(desktopImage) )
                .then( (picture64) => ({
                    src: picture64,
                    title: `${desktopImage.title}`
                }))
                .then( transformedMyFile => dataProvider.create(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        desktopImage: transformedMyFile,
                        mobileImage: newMobileImage
                    }
                }));
        }

        if (resource === 'pages-content') {
            let main_image = undefined
            if (params.data.main_image) {
                const base64 = await convertFileToBase64(params.data.main_image);
                main_image = { src: base64, title: params.data.name }
            };
            return dataProvider.create(resource, {
                ...params,
                data: {
                    ...params.data,
                    main_image
                }
            });
        }

    },

    update: async (resource, params) => {

        if(resource === 'galery'){
        
            const { image1, image2, image3, image4, image5, image6 } = params.data;
            const images = params.data?.images || Array.from({ length: 6 }).fill(null)

            const imagesArray = [image1, image2, image3, image4, image5, image6];

            let editedImages = []

            let count =0;
            for await (const image of imagesArray){
                if(image){
                    const base64 = await convertFileToBase64(image).then(base64image =>{
                        return {
                            src: base64image,
                            title: `${params.data.title}`
                        }
                    })
                    editedImages = [...editedImages, base64];
                }else{
                    editedImages = [...editedImages, images[count]];
                }
                count +=1;
            }

            return dataProvider.update(resource, {
                ...params,
                data: {
                    ...params.data,
                    editedImages,
                },
            })
        }

        if (!params.data.image && !params.data.main_image && !params.data.thumbnail && !params.data.images && !params.data.image1 && !params.data.image2 && !params.data.image3 && !params.data.image4 && !params.data.image5 && !params.data.image6 && !params.data.main_image && !params.data.desktopImage && !params.data.mobileImage) {
            // fallback to the default implementation
            return dataProvider.update(resource, params);
        }
        
        // Freshly dropped pictures are File objects and must be converted to base64 strings
        if(resource === 'users'){

            const newImage = params.data.image;

            return Promise.resolve( convertFileToBase64(newImage) )
                .then( (picture64) => ({
                    src: picture64,
                    title: `${newImage.title}`
                }))
                .then( transformedMyFile => dataProvider.update(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        image: transformedMyFile
                    }
                }));
        }

        if(resource === 'services'){
            const newImage = params.data.image;

            return Promise.resolve( convertFileToBase64(newImage) )
                .then( (picture64) => ({
                    src: picture64,
                    title: `${newImage.title}`
                }))
                .then( transformedMyFile => dataProvider.update(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        image: transformedMyFile
                    }
                }));
        }

        if(resource === 'posts'){

            const newImage = params.data.thumbnail;

            return Promise.resolve( convertFileToBase64(newImage) )
                .then( (picture64) => ({
                    src: picture64,
                    title: `${newImage.title}`
                }))
                .then( transformedMyFile => dataProvider.update(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        thumbnail: transformedMyFile
                    }
                }));
        }

        if(resource === 'events'){

            const newImage = params.data.thumbnail;

            return Promise.resolve( convertFileToBase64(newImage) )
                .then( (picture64) => ({
                    src: picture64,
                    title: `${newImage.title}`
                }))
                .then( transformedMyFile => dataProvider.update(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        thumbnail: transformedMyFile
                    }
                }));
        }

        if(resource === 'plans'){

            const newImage = params.data.image;

            return Promise.resolve( convertFileToBase64(newImage) )
                .then( (picture64) => ({
                    src: picture64,
                    title: `${newImage.title}`
                }))
                .then( transformedMyFile => dataProvider.update(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        image: transformedMyFile
                    }
                }));
        }

        if(resource === 'rooms'){

            const newImage = params.data.thumbnail;

            const { image1, image2, image3, image4, image5, image6, images } = params.data;

            const imagesArray = [image1, image2, image3, image4, image5, image6];

            let editedImages = []

            let count = 0;
            for await (const image of imagesArray){
                if(image){
                    const base64 = await convertFileToBase64(image).then(base64image =>{
                        return {
                            src: base64image,
                            title: `${params.data.title}`
                        }
                    })
                    editedImages = [...editedImages, base64];
                }else{
                    editedImages = [...editedImages, images[count]];
                }
                count +=1;
            }

            if(newImage){
                return Promise.resolve( convertFileToBase64(newImage) )
                .then( (picture64) => ({
                    src: picture64,
                    title: `${newImage.title}`
                }))
                .then( transformedMyFile => dataProvider.update(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        thumbnail: transformedMyFile,
                        editedImages
                    }
                }));
            }else{
                return Promise.resolve()
                .then( () => dataProvider.update(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        editedImages
                    }
                }));
            }
        }

        if(resource === 'spaces'){

            const newImage = params.data.thumbnail;

            const { image1, image2, image3, image4, image5, image6, images } = params.data;

            const imagesArray = [image1, image2, image3, image4, image5, image6];

            let editedImages = []

            let count = 0;
            for await (const image of imagesArray){
                if(image){
                    const base64 = await convertFileToBase64(image).then(base64image =>{
                        return {
                            src: base64image,
                            title: `${params.data.title}`
                        }
                    })
                    editedImages = [...editedImages, base64];
                }else{
                    editedImages = [...editedImages, images[count]];
                }
                count +=1;
            }

            if(newImage){
                return Promise.resolve( convertFileToBase64(newImage) )
                .then( (picture64) => ({
                    src: picture64,
                    title: `${newImage.title}`
                }))
                .then( transformedMyFile => dataProvider.update(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        thumbnail: transformedMyFile,
                        editedImages
                    }
                }));
            }else{
                return Promise.resolve()
                .then( () => dataProvider.update(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        editedImages
                    }
                }));
            }
        }

        if(resource === 'pages'){
        
            const { image1, image2, image3, image4, image5, image6, main_image } = params.data;
            const images = params.data.images ?? Array.from({ length: 6 }).fill(null)
            const imagesArray = [image1, image2, image3, image4, image5, image6];

            let editedImages = [];
            let main_image64;

            if(main_image){
                main_image64 = await convertFileToBase64(main_image).then(base64image =>{
                    return {
                        src: base64image,
                        title: `${params.data.title}`
                    }
                })
            }

            let count =0;
            for await (const image of imagesArray){
                if(image){
                    const base64 = await convertFileToBase64(image).then(base64image =>{
                        return {
                            src: base64image,
                            title: `${params.data.title}`
                        }
                    })
                    editedImages = [...editedImages, base64];
                }else{
                    editedImages = [...editedImages, images[count]];
                }
                count +=1;
            }

            return dataProvider.update(resource, {
                ...params,
                data: {
                    ...params.data,
                    editedImages,
                    main_image64
                },
            })
        }

        if(resource === 'advertisements'){
            const desktopImage = params.data.desktopImage;
            const mobileImage = params.data.mobileImage

            let newDesktopImage = null;
            let newMobileImage = null;

            if(desktopImage){
                newDesktopImage = await convertFileToBase64(desktopImage).then((picture64) => ({
                    src: picture64,
                    title: `${desktopImage.title}`
                }))
            }

            if(mobileImage){
                newMobileImage = await convertFileToBase64(mobileImage).then((picture64) => ({
                    src: picture64,
                    title: `${mobileImage.title}`
                }))
            }

            return Promise.resolve(true)
                .then( () => dataProvider.update(resource, {
                    ...params,
                    data: {
                        ...params.data,
                        desktopImage: newDesktopImage,
                        mobileImage: newMobileImage
                    }
                }));
        }

        if (resource === 'pages-content') {
            let main_image = undefined
            if (params.data.main_image) {
                const base64 = await convertFileToBase64(params.data.main_image);
                main_image = { src: base64, title: params.data.name }
            }
            return dataProvider.update(resource, {
                ...params,
                data: {
                    ...params.data,
                    main_image
                }
            })
        }

    },
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        
        reader.readAsDataURL(file.rawFile);
    });


export default myDataProvider;